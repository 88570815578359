<template>
	<div class="details-adress">
		<div class="details-adress-top">
			<!-- <div class="adress-top-left" v-if="shopInfo.shop_logo">
                <img :src="shopInfo.shop_logo" alt />
            </div>-->
			<div class="adress-top-center">
				<p>{{ shopInfo.shop_name }}</p>
				<p v-if="from=='OrderDate'" style="font-size:0.24rem;color:#606060;">{{ shopInfo.business_hours }}</p>
				<router-link :to=" '/business?shop_id=' +shopInfo.shop_id +'&distance=' + shopInfo.distance "
					class="routerto" v-if="from=='shop'">
					<p>进店逛逛</p>
				</router-link>
			</div>
			<div class="adress-top-right">
				<div class="rigth-home" @click="call(shopInfo.shop_phone)">
					<div>
						<img src="../../assets/dianhua.png" alt />
					</div>
					<div>
						<p>商家</p>
						<p>电话</p>
					</div>
				</div>
			</div>
		</div>
		<div class="details-adress-bottom" @click="openLocation">
			<div v-if="from=='shop'">
				<img src="../../assets/cp05.png" alt />
			</div>
			<span v-if="from=='shop'">距您{{ shopInfo.distance | handleDistance }}</span>
			<p>
				<i v-if="from=='OrderDate'">地址：</i>
				{{ shopInfo.shop_address }}
			</p>
		</div>
	</div>
</template>

<script>
	import {
		changeDis
	} from "@/common/data";
	import {
		openLocation
	} from "@/common/wxJssdk";
	export default {
		props: {
			shopInfo: {
				type: Object,
				default () {
					return {};
				}
			},
			from: {
				type: String,
				default () {
					return "shop";
				}
			}
		},
		methods: {
			//  拨打电话
			call(tel) {
				window.location.href = "tel:" + tel;
			},
			//  跳转地图
			openLocation() {
				let shopInfo = this.shopInfo;
				openLocation({
					latitude: parseFloat(shopInfo.location_lat), // 纬度，浮点数，范围为90 ~ -90
					longitude: parseFloat(shopInfo.location_lng), // 经度，浮点数，范围为180 ~ -180。
					name: shopInfo.shop_name, // 位置名
					address: shopInfo.shop_address // 地址详情说明
				});
			}
		},
		filters: {
			handleDistance(distance) {
				return changeDis(distance);
			}
		}
	};
</script>

<style lang="scss">
	.details-adress {
		padding: 0.2rem 0.26rem 0.34rem 0.23rem;
		box-sizing: border-box;
		background: #fff;
		font-size: 0.28rem;

		.details-adress-top {
			display: flex;
			position: relative;

			.adress-top-left {
				img {
					width: 1.34rem;
					height: 1.34rem;
					display: block;
					border-radius: 0.07rem;
					object-fit: cover;
				}
			}

			.adress-top-center {
				margin-left: 0.12rem;

				p:nth-child(1) {
					font-size: 0.3rem;
					margin-bottom: 0.36rem;
					margin-top: 0.06rem;
				}

				.routerto {
					display: block;
					width: 1.43rem;
					height: 0.51rem;
					border: 1px solid #e40011;
					border-radius: 0.07rem;
					color: #e40011;
					text-align: center;
					line-height: 0.4rem;

					p {
						height: 0.15rem;
					}
				}
			}

			.adress-top-right {
				position: absolute;
				bottom: 0.2rem;
				right: 0;
				width: 1.48rem;
				height: 0.63rem;
				padding-left: 0.3rem;
				//    padding-top: 0.13rem;
				//    padding-bottom: 0.13rem;
				box-sizing: border-box;
				border-left: 2px dashed #c3c3c3;

				.rigth-home {
					display: flex;

					img {
						width: 0.36rem;
						height: 0.43rem;
						display: block;
					}

					div:nth-child(1) {
						padding-right: 0.14rem;
						padding-top: 0.1rem;
						box-sizing: border-box;
					}

					div:nth-child(2) {
						width: 0.66rem;
						font-size: 0.18rem;
						color: #fc6f15;
					}
				}
			}
		}

		.details-adress-bottom {
			margin-top: 0.21rem;
			display: flex;

			div {
				img {
					width: 0.3rem;
					height: 0.36rem;
					display: block;
				}
			}

			span {
				font-size: 0.24rem;
				color: #e40011;
				display: block;
				margin-left: 0.12rem;
				padding-top: 0.08rem;
				box-sizing: border-box;
			}

			p {
				margin-left: 0.12rem;
				font-size: 0.24rem;
				color: #606060;
				padding-top: 0.08rem;
				box-sizing: border-box;
			}
		}
	}
</style>
