<!-- 订单详情 -->
<template>
    <div class="goods-code">
        <div class="info-box" @click="showInfo">
            <p class="spec">{{ goodsInfo.specs_item_value }}</p>
            <div class="status-box">
                <p class="ac-btn" v-if="status.isShowBtn">生成核销码</p>
                <p :class="['status', status.statusColor]">{{ status.statusText }}</p>
                <p class="num">X1</p>
                <img src="@/assets/down.png" class="down" alt />
            </div>
        </div>

        <div class="detail-box" v-show="isShow">
            <div class="action-box">
                <p class="ac-btn" v-if="false">生成核销码</p>
                <template v-if="goodsInfo.is_reserve == 1 && item.cards_status == 1 && item.is_expire != 1">
                    <router-link tag="p" class="ac-btn order"
                        :to="{path: '/appointment',query: { order_id: goodsInfo.order_id, order_cards_id: item.order_cards_id, goods_id: goodsInfo.goods_id }}"
                        v-if="item.cards_reserve == 0">预约</router-link>
                    <template v-if="item.cards_reserve == 1">
                        <p class="ac-btn order">已预约</p>
                        <p class="ac-btn after" @click="toCancel">取消预约</p>
                    </template>
                </template>
                <!-- <p class="ac-btn after" v-if="goodsInfo.is_refund == 1">售后</p> -->
            </div>
            <div class="content-box">
                <div class="qrcode-box border-bottom">
                    <div class="qrcode" ref="qrCodeUrl" v-show="isShowCode"></div>
                    <p :class="['code',{'line':!isShowCode}]">核销码：{{ item.check_code }}</p>
                    <p class="tips">有效期至：{{ item.expire_date }}</p>
                    <p class="tips" v-show="isShowCode">过期作废请尽快到店使用</p>
                    <p class="tips">到店消费时，请出示核销码给商家，勿泄露给他人</p>
                </div>
                <div class="time-info border-bottom" v-if="item.is_expire == 1">
                    <p class="nouse">核销码：{{ item.check_code }}</p>
                    <p>有效期至：{{ item.expire_date }}</p>
                </div>
                <div class="time-info border-bottom" v-if="item.cards_reserve == 1">
                    <p>预约门店：{{ item.reserve_info.reserve_shop_name }}</p>
                    <p>联系人：{{ item.reserve_info.reserve_user }}</p>
                    <p>联系电话：{{ item.reserve_info.reserve_phone }}</p>
                    <p>预约日期：{{ item.reserve_info.reserve_date }}</p>
                    <p>预约时段：{{ item.reserve_info.reserve_time }}</p>
                    <p v-if="item.reserve_info.reserve_notes != ''">预约备注：{{ item.reserve_info.reserve_notes }}</p>
                </div>
                <div class="time-info border-bottom" v-if="item.cards_status == 2">
                    <!-- <p class="nouse">核销码：{{ item.check_code }}</p> -->
                    <p>核销门店：{{ item.check_info.check_shop_name }}</p>
                    <p>核销日期：{{ item.check_info.check_time }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //生成二维码
    import QRCode from "qrcodejs2";
    import {
        orderReserveCancel
    } from "@/http/api";
    
    export default {
        components: {},
        props: {
            item: {
                type: Object,
                default () {
                    return {};
                }
            }, 
            goodsInfo: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        data() {
            return {
                userToken: "",
                isShow: false,
                isCreat: false,
                isShowCode: true
            };
        },

        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.creatQrCode();
        },

        //方法集合
        methods: {
            // 展开折叠
            showInfo() {
                this.isShow = !this.isShow;
            },
            // 生成核销码
            createCode() {
                if (!this.isCreat) {
                    this.isCreat = true;
                    this.isShowCode = true;
                } else {
                    this.$toast.fail("核销码已生成");
                }
            },
            //生成二维码
            creatQrCode() {
                var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                    text: window.location.origin + "/merchantwork?from=QRCode&check_code=" + this.item.check_code,
                    width: 120,
                    height: 120,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            toCancel() {
                this.$dialog.confirm({
                    title: "提示",
                    message: "确定要取消该预约吗？"
                }).then(() => {
                    this.cancelReserve();
                });
            },
            //取消预约
            async cancelReserve() {
                const res = await orderReserveCancel({
                    data: {
                        user_token: this.userToken,
                        order_cards_id: this.item.order_cards_id
                    }
                }); 
                if (res.code == 200) {
                    this.$toast.success("取消预约成功");
                    this.item.cards_reserve = 0;
                }
            }
        },
        filters: {
            orderText(e) {
                switch (e) {
                    case 0:
                        return "预约";
                    case 1:
                        return "已预约";
                }
            }
        },
        
        // 监听属性 类似于data概念
        computed: {
            status() {
                let status = this.item.cards_status,
                    item = this.item;
                switch (status) {
                    case 0:
                        this.isShowCode = false;
                        return {
                            statusText: "已失效",
                            isShowBtn: false,
                            statusColor: "disable"
                        };
                    case 1:
                        if (item.cards_service > 0) {
                            //已售后
                            this.isShowCode = false;
                            return {
                                statusText: "已售后",
                                isShowBtn: false,
                                statusColor: "disable"
                            };
                        } else if (item.is_expire == 1) {
                            //已过期
                            this.isShowCode = false;
                            return {
                                statusText: "已过期",
                                isShowBtn: false,
                                statusColor: ""
                            };
                        } else {
                            return {
                                statusText: "待使用",
                                isShowBtn: true,
                                statusColor: ""
                            };
                        }
                    case 2:
                        this.isShowCode = false;
                        return {
                            statusText: "已核销",
                                isShowBtn: false,
                                statusColor: "green"
                        };
                    default:
                        return {
                            statusText: "未知",
                                isShowBtn: false,
                                statusColor: ""
                        };
                }
            }
        }
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .goods-code {
        background: #ffffff;
        margin-top: 0.26rem;
        border-radius: 0.1rem;
        font-size: 0.3rem;

        .info-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.18rem;
            height: 0.88rem;
            font-size: 0.26rem;

            .status-box {
                display: flex;
                align-items: center;

                .ac-btn {
                    width: 1.44rem;
                    height: 0.32rem;
                    font-size: 0.22rem;
                    color: #ff6868;
                    border: #ff6868 solid 1px;
                    border-radius: 0.1rem;
                    text-align: center;
                    line-height: 0.32rem;
                    margin-right: 0.2rem;
                }

                .status {
                    width: 0.9rem;
                    height: 0.32rem;
                    line-height: 0.32rem;
                    text-align: center;
                    background: #ff6868;
                    border-radius: 0.1rem;
                    font-size: 0.22rem;
                    color: #ffffff;
                }

                .disable {
                    background-color: #cfcfcf;
                }

                .green {
                    background-color: #00bf0d;
                }

                .num {
                    font-size: 0.18rem;
                    color: #888;
                    margin-left: 0.16rem;
                }

                .down {
                    width: 0.24rem;
                    height: 100%;
                    margin-left: 0.1rem;
                }
            }
        }

        .detail-box {
            border-top: #f2f2f2 solid 0.01rem;
            padding: 0.2rem;

            .action-box {
                display: flex;
                justify-content: flex-end;

                .ac-btn {
                    margin-bottom: 0.24rem;
                    width: 1.44rem;
                    height: 0.65rem;
                    font-size: 0.26rem;
                    color: #ff6868;
                    border: #ff6868 solid 1px;
                    border-radius: 0.1rem;
                    text-align: center;
                    line-height: 0.65rem;
                    margin-left: 0.2rem;
                }

                .order {
                    color: #fc6f26;
                    border-color: #fc6f26;
                }

                .after {
                    color: #848484;
                    border-color: #848484;
                }
            }

            .content-box {
                background-color: #f6f6f6;
                border-radius: 0.1rem;

                .qrcode-box {
                    text-align: center;
                    font-size: 0.28rem;

                    .qrcode {
                        margin: 0.41rem auto 0;
                        display: inline-block;
                        background-color: #ffffff;
                        padding: 0.1rem;
                    }

                    p.code {
                        padding-bottom: 0.14rem;

                        &.line {
                            padding-top: 0.3rem;
                            text-decoration: line-through;
                        }
                    }

                    p.tips {
                        font-size: 0.22rem;
                        color: #d4d4d4;
                        padding-bottom: 0.1rem;
                    }
                }

                .time-info {
                    line-height: 0.54rem;
                    font-size: 0.26rem;
                    padding: 0.2rem 0.4rem;

                    .nouse {
                        text-decoration: line-through;
                        color: #999;
                    }
                }

                .border-bottom {
                    border-bottom: #ffffff dashed 0.01rem;
                }

                .border-bottom:last-child {
                    border-bottom: none;
                }
            }
        }
    }
</style>
