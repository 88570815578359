<template>
    <div class="nouse">
        <div class="noOrder-wrap">
            <!-- 订单状态 -->
            <order-status :orderStatus="order_details.order_status"></order-status>
            <div class="noOrder-wrap-bottom">
                <!-- 用户信息 -->
                <order-user-info :orderUser="{address_contacts: order_details.address_contacts, address_phone: order_details.address_phone }"></order-user-info>
                
                <!-- 商品信息 -->
                <order-goods-item :item="order_details"></order-goods-item>
                
                <!-- 核销码信息 -->
                <template v-if="order_details.order_status > 2">
                    <order-goods-about :list="kaquanList" :specName="order_details.specs_item_value"
                        :goodsInfo="order_details">
                    </order-goods-about>
                </template>

                <!-- 店铺信息 -->
                <template v-if="showShop">
                    <div class="shop-info">
                        <p>适用门店</p>
                        <shops-info :shopArr="shopInfo" :goodsId="order_details.goods_id"></shops-info>
                    </div>
                </template>
                
                <!-- 订单操作 -->
                <div class="noOrder-wrap-bottom-7" v-if="order_details.order_status == 1">
                    <div class="noOrder-wrap-bottom-4-l">
                        <p>订单操作</p>
                        <p>可进行取消订单的操作</p>
                    </div>
                    <ul class="noOrder-wrap-bottom-4-r">
                        <li @click="orderAction(1)" :class="{ all: cur == 1 }">取消订单</li>
                    </ul>
                </div>
                <!-- 订单信息 -->
                <order-detail-info :orderDetail="order_details"></order-detail-info>
                <!-- 公众号二维码 -->
                <we-chat-public-q-r-code></we-chat-public-q-r-code>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        CardsOrderDetail,
        getkaqunaLists,
        getGoodsLists,
        CloseOrder,
    } from "@/http/api"; 
    import {
    	getLocationInfo
    } from "@/common/wxJssdk";
    import OrderStatus from "./orderDetailChildren/OrderStatus";
    import OrderUserInfo from "./orderDetailChildren/OrderUserInfo";
    import OrderGoodsAbout from "./orderDetailChildren/OrderGoodsAbout";
    import OrderGoodsItem from "./orderDetailChildren/OrderGoodsItem";
    import ShopsInfo from "../../details/detailsChildren/ShopsInfo";
    import OrderDetailInfo from "./orderDetailChildren/OrderDetailInfo";
    import WeChatPublicQRCode from "@/components/WeChatPublicQRCode";
    export default {
        components: {
            ShopsInfo,
            OrderStatus,
            OrderUserInfo,
            OrderGoodsItem,
            OrderGoodsAbout,
            OrderDetailInfo,
            WeChatPublicQRCode
        },
        data() {
            return {
                userToken: "",
                order_id: '',
                order_details: {},
                cur: 0,
                showShop: false,
                kaquanList: [],
				location:{} 
            };
        },
        mounted() {
            if (this.$route.query.id) {
                this.order_id = this.$route.query.id;
                this.userToken = this.$LStorage.getItem("userToken").user_token;
                this.OrderDetail();
                //this.location = this.$LStorage.getItem("location");
            } else {
                this.$dialog.alert({
                    message: '订单详情页面参数异常, 点击跳转到订单列表看看',
                }).then(() => {
                    this.$router.replace({
                        path: "/allorder"
                    });
                });
            }
        },

        methods: {
            //获取订单详情
            async OrderDetail() {
                const res = await CardsOrderDetail({
                    data: {
                        user_token: this.userToken,
                        order_id: this.order_id
                    }
                });
                if (res.code == 200) {
                    this.order_details = res.data;
                    if (res.data.order_status > 2) {
                        this.getkaqunaLists();
						
						//  执行获取店铺列表数据
						let that = this;
						getLocationInfo("gcj02", function(locationData) {
							console.log(locationData)
							let curSiteInfo = that.$LStorage.getItem('curSiteInfo');
							if (locationData) {
								that.location = {
									location_lng: locationData.longitude,
									location_lat: locationData.latitude,
								}
							} else {
								that.location = {
									location_lng: curSiteInfo.location_lng,
									location_lat: curSiteInfo.location_lat,
								}
							}
							that.shopGoodsLists(that.order_details.goods_id);
						}); 
                    }
                }
            },

            //获取订单详情(卡券列表)
            async getkaqunaLists() {
                const res = await getkaqunaLists({
                    data: {
                        user_token: this.userToken,
                        order_id: this.order_id
                    }
                });
                if (res.code == 200) {
                    this.kaquanList = res.data;
                }
            },
            
            //  获取店铺列表
            async shopGoodsLists(goods_id) { 
                const res = await getGoodsLists({
                    data: {
                        location_lng: this.location.location_lng,
                        location_lat: this.location.location_lat,
                        goods_id: goods_id
                    }
                });
                if (res.data.length > 0) {
                    this.showShop = true;
                }
                this.shopInfo = res.data;
            },
            
            // 订单操作
            orderAction(types) {
                if (types == 1) {
                    this.closeOrder();
                }
            },

            // 取消订单
            async closeOrder() {
                const res = await CloseOrder({
                    data: {
                        order_id: this.order_details.order_id,
                        user_id: this.user_info.user_id
                    }
                });
                if (res.code == "200") {
                    this.$toast.success("取消成功");
                    this.$router.go(-1);
                } else {
                    this.$toast(res.msgs);
                }
            },
        } 
    };
</script>

<style lang="scss">
    .nouse {
        width: 100%;

        .noOrder-wrap {
            width: 100%;

            .noOrder-wrap-bottom {
                width: 100%;
                background: #f6f6f6;
                padding: 0.25rem 0.2rem 0.91rem 0.23rem;
                box-sizing: border-box;

                .shop-info {
                    margin-top: 0.24rem;
                    border-radius: 0.1rem;
                    overflow: hidden;
                    background-color: #fff;

                    >p {
                        font-size: 0.3rem;
                        padding: 0 0.2rem;
                        line-height: 0.8rem;

                    }
                }

                .noOrder-wrap-bottom-7 {
                    width: 100%;
                    background: #fff;
                    padding: 0.24rem 0.15rem 0.25rem 0.17rem;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 0.26rem;
                    border-radius: 0.09rem;

                    .noOrder-wrap-bottom-4-l {
                        p:nth-child(1) {
                            font-size: 0.25rem;
                            color: #333;
                        }

                        p:nth-child(2) {
                            font-size: 0.23rem;
                            color: #646464;
                            margin-top: 0.2rem;
                        }
                    }

                    .noOrder-wrap-bottom-4-r {
                        display: flex;

                        li {
                            width: 1.37rem;
                            height: 0.55rem;
                            border: 1px solid #bbbbbb;
                            font-size: 0.24rem;
                            text-align: center;
                            line-height: 0.55rem;
                            color: #bbbbbb;
                            border-radius: 0.07rem;
                            margin-top: 0.16rem;
                        }

                        li:nth-child(1) {
                            margin-right: 0.11rem;
                        }

                        .all {
                            color: #e40011;
                            border: 1px solid #e40011;
                        }
                    }
                }
            }
        }
    }
</style>
