<!-- 订单详情 商品 -->
<template>
    <div class="tabbar">
        <p class="title">订单商品</p>
        <div class="tabbar-all">
            <router-link tag="div" class="tabbar-all-center" :to="'/details?goods_id=' + item.goods_id + '&types=1'">
                <img :src="item.goods_image" alt />
                <div class="tabbar-all-c-r">
                    <p>{{ item.goods_name }}</p>
                    <p class="p10">{{ item.specs_item_value }}</p>
                    <div class="tabbar-all-c-r-b">
                        <p>￥{{ item.goods_price }}</p>
                        <p>x{{ item.goods_nums }}</p>
                    </div>
                </div>
            </router-link>
            <div class="time">使用有效期至: {{ item.expire_time}}</div>
            <div class="note">
                <p class="name">备注</p>
                <p class="content">{{ item.consume_notes}} </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            item: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        data() {
            return {};
        },
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .tabbar {
        background-color: #fff;
        border-radius: 0.1rem;

        .title {
            font-size: 0.3rem;
            line-height: 0.88rem;
            padding: 0.1rem;
            font-weight: 600;
            border-bottom: 0.01rem solid #f2f2f2;
        }

        .tabbar-all {
            // width: 7.05rem;
            background: #fff;
            margin-bottom: 0.3rem;

            .tabbar-all-top {
                height: 0.82rem;
                display: flex;
                align-items: center;
                padding-right: 0.13rem;
                box-sizing: border-box;

                .tabbar-all-top-l {
                    display: flex;
                    align-items: center;
                    padding-left: 0.1rem;
                    box-sizing: border-box;

                    p:nth-child(1) {
                        width: 0.36rem;
                        height: 0.36rem;
                        border-radius: 0.04rem;
                        font-size: 0.1rem;
                        color: #ffff;
                        background: #000;
                    }

                    p:nth-child(2) {
                        font-size: 0.27rem;
                        margin-left: 0.1rem;
                        margin-right: 0.1rem;
                    }
                }

                .tabbar-all-top-r {
                    font-size: 0.26rem;
                    color: #e50012;
                    margin-left: 3.14rem;
                }
            }

            .tabbar-all-center {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 0.2rem 0.1rem 0.2rem 0.1rem;
                box-sizing: border-box;
                border-bottom: 0.01rem solid #f2f2f2;

                img {
                    width: 2.46rem;
                    height: 1.79rem;
                    object-fit: cover;
                }

                .tabbar-all-c-r {
                    // margin-left: 0.2rem;
                    width: calc(100% - 2.66rem);

                    p:nth-child(1) {
                        width: 100%;
                        font-size: 0.3rem;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .p10 {
                        font-size: 0.26rem;
                        height: 0.35rem;
                        line-height: 0.35rem;
                        color: #999;
                        margin-top: 0.2rem;
                    }

                    .tabbar-all-c-r-b {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        font-size: 0.23rem;
                        margin-top: 0.6rem;

                        p:nth-child(1) {
                            color: #eb676c;
                        }

                        p:nth-child(2) {
                            color: #888888;
                        }
                    }
                }
            }

            .time {
                font-size: 0.26rem;
                padding: 0 0.2rem;
                line-height: 0.68rem;
            }

            .note {
                display: flex;
                font-size: 0.26rem;
                padding: 0 0.2rem;
                padding-bottom: 0.2rem;

                .name {
                    margin-right: 10px;
                }

                .content {
                    width: calc(100% - 10px);
                }
            }
        }
    }
</style>
