<template>
	<div class="details-adress-seat"> 
		<router-link :to="'/Business?shop_id='+ shopItem.shop_id + '&distance=' + shopItem.distance"
			class="details-adress-seat-top" tag="div">
			<p>{{shopItem.shop_name}}</p>
			<svg t="1597741975700" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
				p-id="3144" width="16" height="16">
				<path
					d="M704 512c0-8.288-3.232-15.776-8.4-21.456l0.08-0.064-320-352-0.08 0.08A31.76 31.76 0 0 0 352 128a32 32 0 0 0-32 32c0 8.288 3.232 15.76 8.4 21.456l-0.08 0.08L628.752 512 328.32 842.464l0.08 0.08A31.776 31.776 0 0 0 320 864a32 32 0 0 0 32 32 31.84 31.84 0 0 0 23.6-10.544l0.08 0.08 320-352-0.08-0.08c5.168-5.68 8.4-13.168 8.4-21.456z"
					p-id="3145" fill="#8a8a8a" />
			</svg>
		</router-link>
		<div class="details-adress-seat-bottom">
			<div>
				<img src="../../assets/cp05.png" alt />
			</div>
			<span @click="openLocation">距您{{shopItem.distance | handleDistance}}</span>
			<p class="p1" @click="openLocation">{{shopItem.shop_address}}</p>
			<div class="rigth-hom-1">
				<div class="rigth-home" @click="call(shopItem.shop_phone)">
					<div>
						<img src="../../assets/dianhua.png" alt />
					</div>
					<div>
						<p>商家</p>
						<p>电话</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		changeDis
	} from "@/common/data";
	import {
		openLocation
	} from "@/common/wxJssdk";
	export default {
		props: {
			shopItem: {
				type: Object,
				default () {
					return {};
				}
			}
		},
		methods: {
			//拨打电话
			call(tel) {
				window.location.href = "tel:" + tel;
			},
			//跳转地图
			openLocation() {
				let shopInfo = this.shopItem;
				openLocation({
					latitude: parseFloat(shopInfo.location_lat), // 纬度，浮点数，范围为90 ~ -90
					longitude: parseFloat(shopInfo.location_lng), // 经度，浮点数，范围为180 ~ -180。
					name: shopInfo.shop_name, // 位置名
					address: shopInfo.shop_address // 地址详情说明
				});
			}
		},
		filters: {
			handleDistance(distance) {
				return changeDis(distance);
			}
		}
	};
</script>

<style lang="scss">
	.details-adress-seat {
		width: 100%;
		padding: 0.3rem 0.24rem;
		box-sizing: border-box;
		border-top: 1px solid #f6f6f6;
		background: #fff;

		.details-adress-seat-top {
			display: flex;
			font-size: 0.3rem;

			p:nth-child(1) {
				margin-right: 0.11rem;
			}

			svg {
				margin-top: 0.06rem;
				margin-left: 0.14rem;
			}
		}

		.details-adress-seat-bottom {
			margin-top: 0.21rem;
			display: flex;
			position: relative;

			div:nth-child(1) {
				img {
					width: 0.3rem;
					height: 0.36rem;
					display: block;
				}
			}

			span {
				font-size: 0.24rem;
				color: #e40011;
				display: block;
				margin-left: 0.12rem;
				padding-top: 0.08rem;
				box-sizing: border-box;
			}

			.p1 {
				margin-left: 0.12rem;
				font-size: 0.24rem;
				color: #606060;
				padding-top: 0.08rem;
				box-sizing: border-box;
				width: 3.38rem;
			}

			.rigth-home {
				display: flex;
				width: 1.48rem;
				position: absolute;
				bottom: 0.2rem;
				right: 0;
				width: 1.48rem;
				height: 0.63rem;
				padding-left: 0.3rem;
				box-sizing: border-box;

				img {
					width: 0.36rem;
					height: 0.43rem;
					display: block;
				}

				div:nth-child(1) {
					padding-right: 0.14rem;
					padding-top: 0.1rem;
					box-sizing: border-box;
				}

				div:nth-child(2) {
					font-size: 0.18rem;
					color: #fc6f15;
				}
			}
		}
	}
</style>
