<!-- 订单详情 -->
<template>
    <div class="goods-code-list">
        <order-qrcode v-for="(item, index) in list" :key="index" :item="item" :goodsInfo="goodsInfo"></order-qrcode>
    </div>
</template>

<script>
    import OrderQrcode from "./OrderQrcode";

    export default {
        components: {
            OrderQrcode
        },
        props: {
            list: {
                type: Array,
                default () {
                    return [];
                }
            },
            goodsInfo: {
                type: Object,
                default () {
                    return {};
                }
            },
        },
        data() {
            return {};
        },
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .goods-code-list {
        p {
            font-size: 0.3rem;
            font-weight: 600;
        }
    }
</style>
