<!--  -->
<template>
    <div class="noOrder-wrap-bottom-5">
        <p class="p6">订单信息</p>
        <div class="bottom">
            <p>订单编号：</p>
            <p>{{ orderDetail.order_no }}</p>
        </div>
        <div class="bottom">
            <p>下单时间：</p>
            <p>{{ orderDetail.create_time }}</p>
        </div>
        <div class="bottom">
            <p>下单金额：</p>
            <p>{{ orderDetail.order_amount }}</p>
        </div>
        <template v-if="orderDetail.order_status > 1">
            <div class="bottom">
                <p>支付方式：</p>
                <p>{{ orderDetail.pay_types | payWay }}</p>
            </div>
            <div class="bottom">
                <p>付款时间：</p>
                <p>{{ orderDetail.pay_time }}</p>
            </div>
            <div class="bottom">
                <p>支付金额：</p>
                <p>{{ orderDetail.goods_amount }}</p>
            </div>
        </template>
        <div class="bottom" v-if="orderDetail.order_status == 4">
            <p>完成时间：</p>
            <p>{{ orderDetail.receive_time }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            orderDetail: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        // 接收父组件参数
        data() {
            return {};
        },
        //过滤器
        filters: {
            payWay(e) {
                switch (e) {
                    case 1:
                        return "微信";
                        break;
                    case 3:
                        return "通联支付";
                        break;
                }
            }
        },
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .noOrder-wrap-bottom-5 {
        margin-top: 0.2rem;
        padding: 0.2rem;
        border-radius: 0.1rem;
        background: #fff;
        margin-bottom: 0.2rem;

        .p6 {
            font-size: 0.28rem;
            border-left: 0.07rem solid #e40011;
            padding-left: 0.08rem;
            line-height: 0.34rem;
            margin-bottom: 0.2rem;
        }

        .bottom {
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            color: #484848;
            padding-left: 0.13rem;
            line-height: 0.52rem;

        }
    }
</style>
