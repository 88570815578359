<!-- 订单详情 用户信息 -->
<template>
    <ul class="noOrder-wrap-bottom-1">
        <li>
            <p>
                <svg t="1598241119684" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="6806" width="16" height="16">
                    <path
                        d="M501.333333 501.333333c-117.333333 0-213.333333-96-213.333333-213.333333s96-213.333333 213.333333-213.333333 213.333333 96 213.333334 213.333333-96 213.333333-213.333334 213.333333z m0-384c-93.866667 0-170.666667 76.8-170.666666 170.666667s76.8 170.666667 170.666666 170.666667 170.666667-76.8 170.666667-170.666667-76.8-170.666667-170.666667-170.666667zM900.266667 917.333333l-49.066667-362.666666-211.2-23.466667v42.666667l172.8 19.2v4.266666l36.266667 277.333334H174.933333l36.266667-277.333334v-4.266666l172.8-19.2v-42.666667L172.8 554.666667 123.733333 917.333333z"
                        fill p-id="6807" />
                    <path d="M256 640h128v42.666667h-128z" fill="#c4c4c4" p-id="6808" />
                </svg>
            </p>
            <div>
                <p>客户名：{{ orderUser.address_contacts }}</p>
            </div>
        </li>
        <li>
            <p>
                <svg t="1598248560791" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="7651" width="16" height="16">
                    <path
                        d="M819.2 204.8V102.4a51.2 51.2 0 0 0-51.2-51.2H256a51.2 51.2 0 0 0-51.2 51.2v102.4h614.4z m0 51.2H204.8v665.6a51.2 51.2 0 0 0 51.2 51.2h512a51.2 51.2 0 0 0 51.2-51.2V256zM256 0h512a102.4 102.4 0 0 1 102.4 102.4v819.2a102.4 102.4 0 0 1-102.4 102.4H256a102.4 102.4 0 0 1-102.4-102.4V102.4a102.4 102.4 0 0 1 102.4-102.4z m179.2 844.8h153.6a25.6 25.6 0 0 1 0 51.2h-153.6a25.6 25.6 0 0 1 0-51.2z"
                        p-id="7652" />
                </svg>
            </p>
            <div>
                <p>手机号：{{ orderUser.address_phone }}</p>
            </div>
        </li>
    </ul>
</template>

<script>
    export default {
        components: {},
        props: {
            orderUser: {
                type: Object,
                default () {
                    return {};
                }
            }
        },
        data() {
            return {};
        }, 
    };
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .noOrder-wrap-bottom-1 {
        width: 100%;
        background: #fff;
        border-radius: 0.08rem;
        margin-bottom: 0.25rem;

        li {
            height: 1.07rem;
            display: flex;
            border-bottom: 0.01rem solid #f2f2f2;
            align-items: center;
            color: #333333;
            padding-left: 0.25rem;
            box-sizing: border-box;
            font-size: 0.26rem;

            svg {
                display: block;
                margin-right: 0.15rem;
            }

            div {
                display: flex;
                align-items: center;

                p {
                    font-size: 0.27rem;
                }
            }
        }

        li:last-child {
            border: none;
        }
    }
</style>
