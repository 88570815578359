<!-- 订单详情 订单状态 -->
<template>
    <div class="noOrder-wrap-top">
        <p>{{ titleStatus }}</p>
        <p>
            <img src="@/assets/tranbg2.png" alt v-if="orderStatus == 1" />
            <img src="@/assets/tranbg.png" alt v-if="orderStatus == 2" />
            <img src="@/assets/tranbg.png" alt v-else-if="orderStatus == 3" />
            <img src="@/assets/tranbg1.png" alt v-else-if="orderStatus == 4" />
            <img src="@/assets/tranbg.png" alt v-else />
        </p>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            orderStatus: {
                type: Number,
                default () {
                    return
                }
            }
        },
        data() {
            return {}
        },
        computed: {
            titleStatus() {
                let status = this.orderStatus;
                switch (status) {
                    case 0:
                        return "已取消";
                    case 1:
                        return "待付款";
                    case 2:
                        return "已付款";
                    case 3:
                        return "待使用";
                    case 4:
                        return "已使用";
                    default:
                        return "未知";
                }
            }
        }
    }
</script>
<style lang='scss' scoped>
    //@import url(); 引入公共css类
    .noOrder-wrap-top {
        width: 100%;
        height: 1.48rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e40011;

        p {
            font-size: 0.3rem;
            color: #fff;
        }

        img {
            width: 0.77rem;
            height: 0.77rem;
            margin-left: 0.15rem;
        }
    }
</style>
