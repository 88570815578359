<template>
	<div class="shop-box" v-if="shopArr.length > 0">
		<div v-for="(item, index) in shopArr" :key="index">
			<!-- 大图展示 -->
			<shop v-if="index == 0" :shopInfo="item"></shop>
			<!-- 店铺列表 -->
			<shops-list v-else :shopItem="item"></shops-list>
		</div>
		<div class="btn">
			<router-link :to="'/allstore?goods_id=' + goodsId">
				<p>全部可用门店</p>
			</router-link>
		</div>
	</div>
</template>

<script>
	import Shop from "../../../components/shopsInfo/Shop";
	import ShopsList from "../../../components/shopsInfo/ShopsList";

	export default {
		components: {
			Shop,
			ShopsList
		},
		props: {
			goodsDetails: {
				type: Object,
				default () {
					return {};
				}
			},
			shopArr: {
				type: Array,
				default () {
					return [];
				}
			},
			goodsId: {
				type: String,
				default () {
					return "";
				}
			}
		},
		methods: {}
	};
</script>

<style lang="scss" scope>
	.shop-box {
		background-color: #fff;
		padding-bottom: 0.4rem;
	}

	.btn {
		box-sizing: border-box;

		a {
			display: block;

			p {
				width: 2.45rem;
				height: 0.55rem;
				text-align: center;
				line-height: 0.55rem;
				color: #fc6f15;
				font-size: 0.22rem;
				background: #ffe5d5;
				border-radius: 0.2rem;
				margin: 0 auto;
			}
		}
	}
</style>
